import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { AgentsService } from '@app/core/service/agents.service';
import { DealsService } from '@app/core/service/deals.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-repair-form',
  templateUrl: './repair-form.component.html',
  styleUrls: ['./repair-form.component.scss']
})
export class RepairFormComponent implements OnInit {
  repairCreateFrom: FormGroup;

  @Input()
    dealId? = '';

  @Output()
    onSubmitStepChange? = new EventEmitter<any>();
    
  dealData: any;
  isLoading: boolean;
  user: Authentication.User;
  agentName=''

  constructor(public activeModal: NgbActiveModal,
      private formBuilder: FormBuilder,
      private toastrService: ToastrService,
      public authenticationService: AuthenticationService,
      private dealsService: DealsService,
      private agentsService: AgentsService) { 
        this.user = this.authenticationService.credentials.user;
        this.dealData = this.dealsService.dealData;
        this.createForm();    
  }

  ngOnInit() {
    if(this.dealData.agencyId){
      this.getAgencyList();
    }
  }

  getAgencyList() {
    this.isLoading = true;
    this.agentsService
      .getAgencyList()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        response.records.forEach((agency: any) => {
          if (this.dealData.agencyId === agency._id) {
            this.agentName = agency.agencyname;
          }
        });
      });
    // console.log('333333');
  }

  private createForm() {
    this.repairCreateFrom = this.formBuilder.group({
      primaryName: [this.dealData.repairForm?this.dealData.repairForm.primaryName:'', [Validators.required]],
      primaryTitle: [this.dealData.repairForm?this.dealData.repairForm.primaryTitle:'', [Validators.required]],
      primaryPhone: [
        this.dealData.repairForm?this.dealData.repairForm.primaryPhone:'',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      primaryEmail: [this.dealData.repairForm?this.dealData.repairForm.primaryEmail:'', Validators.compose([Validators.required, Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      alternateName: [this.dealData.repairForm?this.dealData.repairForm.alternateName:'', [Validators.required]],
      alternateTitle: [this.dealData.repairForm?this.dealData.repairForm.alternateTitle:'', [Validators.required]],
      alternatePhone: [
        this.dealData.repairForm?this.dealData.repairForm.alternatePhone:'',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      alternateEmail: [this.dealData.repairForm?this.dealData.repairForm.alternateEmail:'', Validators.compose([Validators.required, Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      repairFacilityName: [this.dealData.repairForm?this.dealData.repairForm.repairFacilityName:'', [Validators.required]],
      repairFacilityTypeOnSite: [this.dealData.repairForm?this.dealData.repairForm.repairFacilityTypeOnSite:false],
      repairFacilityTypePreferredTieBack: [this.dealData.repairForm?this.dealData.repairForm.repairFacilityTypePreferredTieBack:false],
      repairFacilityAddress: [this.dealData.repairForm?this.dealData.repairForm.repairFacilityAddress:'', [Validators.required]],
      repairFacilityTaxId: [this.dealData.repairForm?this.dealData.repairForm.repairFacilityTaxId:'', [Validators.required]],
      repairFacilityPhone: [
        this.dealData.repairForm?this.dealData.repairForm.repairFacilityPhone:'',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12)
        ])
      ],
      repairFacilityEmail: [this.dealData.repairForm?this.dealData.repairForm.repairFacilityEmail:'', Validators.compose([Validators.required, Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      repairFacilityLaborRate: [this.dealData.repairForm?this.dealData.repairForm.repairFacilityLaborRate:this.dealData.laborReimbursementRate, [Validators.required]],
      repairFacilityLaborTax: [this.dealData.repairForm?this.dealData.repairForm.repairFacilityLaborTax:this.dealData.laborTax, [Validators.required]],
    });
  }

  createRepairForm() {
    this.isLoading = true;
    let payload = { dealId: this.dealId, repairForm: this.repairCreateFrom.value }
    this.dealsService
      .stepSubmit(this.user._id, 'repairForm', payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.repairCreateFrom.markAsPristine();
        this.isLoading = true;
        //console.log(response, 'response step 8');
        if (response && response.filename) {
            this.isLoading = false;
            this.activeModal.close(response);
          // this.dealsService.getDealById(
          //   this.user._id,
          //   this.dealId
          // );
          // this.dealsService.dealData$.subscribe((dealDatas:any) => {
          //   this.isLoading = false;
          //   this.activeModal.close(dealDatas);
          // })
        } else {
          this.toastrService.warning('something went Wrong');
        }
      });
  }

  sameAsPrimary(check:any){
    if (check) {
      this.repairCreateFrom
        .get('primaryName')
        .setValue(this.dealData.ownerName);
      this.repairCreateFrom
        .get('primaryTitle')
        .setValue(this.dealData.ownerTitle);
      this.repairCreateFrom
        .get('primaryPhone')
        .setValue(this.dealData.ownerPhoneNumber);
      this.repairCreateFrom
        .get('primaryEmail')
        .setValue(this.dealData.ownerEmail);
    }
  }

  sameAsPrimary1(check:any){
    if (check) {
      this.repairCreateFrom
        .get('alternateName')
        .setValue(this.dealData.ownerName);
      this.repairCreateFrom
        .get('alternateTitle')
        .setValue(this.dealData.ownerTitle);
      this.repairCreateFrom
        .get('alternatePhone')
        .setValue(this.dealData.ownerPhoneNumber);
      this.repairCreateFrom
        .get('alternateEmail')
        .setValue(this.dealData.ownerEmail);
    }
  }

  checkValidfields() {
    if (this.repairCreateFrom.invalid || this.isLoading) {
      this.toastrService.warning('Please fill all required fields.');
      for (const i in this.repairCreateFrom.controls) {
        this.repairCreateFrom.controls[i].markAsTouched();
      }
    }
  }

}
