import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { AgentsService } from '@app/core/service/agents.service';
import { DealsService } from '@app/core/service/deals.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-ppm-form',
  templateUrl: './ppm-form.component.html',
  styleUrls: ['./ppm-form.component.scss']
})
export class PpmFormComponent implements OnInit {
  PPMCreateFrom: FormGroup;

  @Input()
    dealId? = '';

  @Input()
    product? = '';

  @Output()
    onSubmitStepChange? = new EventEmitter<any>();
    
  dealData: any;
  PPMData: any;
  isLoading: boolean;
  user: Authentication.User;

  months = Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`);
  services = Array.from({ length: 10 }, (_, i) => `Month ${i + 1}`);
  agentName='';
  proInfo='';
  
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    public authenticationService: AuthenticationService,
    private dealsService: DealsService,
    private agentsService: AgentsService) { 
      this.user = this.authenticationService.credentials.user;
      this.dealData = this.dealsService.dealData;
}

  ngOnInit() {
    if(this.dealData.PPMForm && this.dealData.PPMForm[this.product]){
      this.PPMData=this.dealData.PPMForm[this.product];
    }     
    this.createForm();    
    if(this.dealData.agencyId){
      this.getAgencyList();
    }
  }

  getAgencyList() {
    this.isLoading = true;
    this.agentsService
      .getAgencyList()
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        response.records.forEach((agency: any) => {
          if (this.dealData.agencyId === agency._id) {
            this.agentName = agency.agencyname;
          }
        });
      });
    // console.log('333333');
  }

  private createForm() {
    let scheduledCare=false;
    let plusCare=false;
    if (this.dealData &&
      this.dealData.product) {
      this.dealData.product.forEach((cat: any, index: number) => {
        if(cat.slug==this.product)
        cat.category.forEach((prod: any, inde: number) => {
          if (prod.slug == 'maintenance-plus') {
            prod.product.forEach((child: any, ind: number) => {
              if (child.selection){
                if(child.slug=='plus-care') plusCare=true;
                if(child.slug=='scheduled-care') scheduledCare=true;
              }
            })
          }
        });
      });
    }
    this.PPMCreateFrom = this.formBuilder.group({
      scheduledCare: [this.PPMData ? this.PPMData.scheduledCare : scheduledCare],
      plusCare: [this.PPMData ? this.PPMData.plusCare : plusCare],
      vechileNew: [this.PPMData ? this.PPMData.vechileNew : false],
      vechileCertifiedPreOwned: [this.PPMData ? this.PPMData.vechileCertifiedPreOwned : false],
      vechilePreOwned: [this.PPMData ? this.PPMData.vechilePreOwned : false],
      privateYes: [this.PPMData ? this.PPMData.privateYes : false],
      privateNo: [this.PPMData ? this.PPMData.privateNo : false],
      vechileAgeLimit: [this.PPMData ? this.PPMData.vechileAgeLimit : '', [Validators.required]],
      milesOnOdometer: [this.PPMData ? this.PPMData.milesOnOdometer : '', [Validators.required]],
      eligibleVehicles: [this.PPMData ? this.PPMData.eligibleVehicles : '', [Validators.required]],
      // month1: [this.PPMData ? this.PPMData.month1 : ''],
      // miles1: [this.PPMData ? this.PPMData.miles1 : ''],
      // month2: [this.PPMData ? this.PPMData.month2 : ''],
      // miles2: [this.PPMData ? this.PPMData.miles2 : ''],
      // month3: [this.PPMData ? this.PPMData.month3 : ''],
      // miles3: [this.PPMData ? this.PPMData.miles3 : ''],
      // month4: [this.PPMData ? this.PPMData.month4 : ''],
      // miles4: [this.PPMData ? this.PPMData.miles4 : ''],
      // month5: [this.PPMData ? this.PPMData.month5 : ''],
      // miles5: [this.PPMData ? this.PPMData.miles5 : ''],
      // month6: [this.PPMData ? this.PPMData.month6 : ''],
      // miles6: [this.PPMData ? this.PPMData.miles6 : ''],
      // month7: [this.PPMData ? this.PPMData.month7 : ''],
      // miles7: [this.PPMData ? this.PPMData.miles7 : ''],
      // month8: [this.PPMData ? this.PPMData.month8 : ''],
      // miles8: [this.PPMData ? this.PPMData.miles8 : ''],
      // month9: [this.PPMData ? this.PPMData.month9 : ''],
      // miles9: [this.PPMData ? this.PPMData.miles9 : ''],
      // month10: [this.PPMData ? this.PPMData.month10 : ''],
      // miles10: [this.PPMData ? this.PPMData.miles10 : ''],
      // month11: [this.PPMData ? this.PPMData.month11 : ''],
      // miles11: [this.PPMData ? this.PPMData.miles11 : ''],
      // month12: [this.PPMData ? this.PPMData.month12 : ''],
      // miles12: [this.PPMData ? this.PPMData.miles12 : ''],
      // service1: [this.PPMData ? this.PPMData.service1 : ''],
      // service2: [this.PPMData ? this.PPMData.service2 : ''],
      // service3: [this.PPMData ? this.PPMData.service3 : ''],
      // service4: [this.PPMData ? this.PPMData.service4 : ''],
      // service5: [this.PPMData ? this.PPMData.service5 : ''],
      // service6: [this.PPMData ? this.PPMData.service6 : ''],
      // service7: [this.PPMData ? this.PPMData.service7 : ''],
      // service8: [this.PPMData ? this.PPMData.service8 : ''],
      // service9: [this.PPMData ? this.PPMData.service9 : ''],
      // service10: [this.PPMData ? this.PPMData.service10 : ''],
      // interval1: [this.PPMData ? this.PPMData.interval1 : ''],
      // interval2: [this.PPMData ? this.PPMData.interval2 : ''],
      // interval3: [this.PPMData ? this.PPMData.interval3 : ''],
      // interval4: [this.PPMData ? this.PPMData.interval4 : ''],
      // interval5: [this.PPMData ? this.PPMData.interval5 : ''],
      // interval6: [this.PPMData ? this.PPMData.interval6 : ''],
      // interval7: [this.PPMData ? this.PPMData.interval7 : ''],
      // interval8: [this.PPMData ? this.PPMData.interval8 : ''],
      // interval9: [this.PPMData ? this.PPMData.interval9 : ''],
      // interval10: [this.PPMData ? this.PPMData.interval10 : ''],
      // reimbursement1: [this.PPMData ? this.PPMData.reimbursement1 : ''],
      // reimbursement2: [this.PPMData ? this.PPMData.reimbursement2 : ''],
      // reimbursement3: [this.PPMData ? this.PPMData.reimbursement3 : ''],
      // reimbursement4: [this.PPMData ? this.PPMData.reimbursement4 : ''],
      // reimbursement5: [this.PPMData ? this.PPMData.reimbursement5 : ''],
      // reimbursement6: [this.PPMData ? this.PPMData.reimbursement6 : ''],
      // reimbursement7: [this.PPMData ? this.PPMData.reimbursement7 : ''],
      // reimbursement8: [this.PPMData ? this.PPMData.reimbursement8 : ''],
      // reimbursement9: [this.PPMData ? this.PPMData.reimbursement9 : ''],
      // reimbursement10: [this.PPMData ? this.PPMData.reimbursement10 : '']      
    });
    this.months.forEach((_, index) => {
      this.PPMCreateFrom.addControl(`month${index + 1}`, this.formBuilder.control(this.PPMData ? this.PPMData[`month${index + 1}`] : ''));
      this.PPMCreateFrom.addControl(`miles${index + 1}`, this.formBuilder.control(this.PPMData ? this.PPMData[`miles${index + 1}`] : ''));
    });
    this.services.forEach((_, index) => {
      this.PPMCreateFrom.addControl(`service${index + 1}`, this.formBuilder.control(this.PPMData ? this.PPMData[`service${index + 1}`] : ''));
      this.PPMCreateFrom.addControl(`interval${index + 1}`, this.formBuilder.control(this.PPMData ? this.PPMData[`interval${index + 1}`] : ''));
      this.PPMCreateFrom.addControl(`reimbursement${index + 1}`, this.formBuilder.control(this.PPMData ? this.PPMData[`reimbursement${index + 1}`] : ''));
    });
  }

  createRepairForm() {
    this.isLoading = true;
    let payload = { dealId: this.dealId, product:this.product, PPMForm: this.PPMCreateFrom.value }
    this.dealsService
      .stepSubmit(this.user._id, 'PPMForm', payload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((response: any) => {
        this.isLoading = true;
        //console.log(response, 'response step 8');
        if (response && response.filename) {
          this.PPMCreateFrom.markAsPristine();
          this.PPMCreateFrom.reset();
          this.isLoading = false;
          this.activeModal.close(response);
          // this.dealsService.getDealById(
          //   this.user._id,
          //   this.dealId
          // );
          // this.dealsService.dealData$.subscribe((dealDatas:any) => {
          //   this.isLoading = false;
          //   this.activeModal.close(dealDatas);
          // })
        } else {
          this.toastrService.warning('something went Wrong');
        }
      });
  }
  checkValidfields() {
    if (this.PPMCreateFrom.invalid || this.isLoading) {
      this.toastrService.warning('Please fill all required fields.');
      for (const i in this.PPMCreateFrom.controls) {
        this.PPMCreateFrom.controls[i].markAsTouched();
      }
    }
  }

  getCatAttr(cat:string,attr:string){
    let catproducts=this.dealData.product.find((element:any) => element.slug==cat);
    return catproducts[attr]?catproducts[attr]:cat;
  }
}
