import { CurrencyPipe, DatePipe } from '@angular/common';
import { EventEmitter } from '@angular/core';
import { COUNTRIES, DATE_TIME_FORMATE, TIMEZONES } from '@app/core/constants';
var UtilService = /** @class */ (function () {
    function UtilService(currencyPipe) {
        this.currencyPipe = currencyPipe;
        this.datePipe = new DatePipe('en-US');
        this.stepClick = true;
        this._missedError = '';
        this.missedErrors$ = new EventEmitter();
        this.selectedProducts = [];
        this.$onProductChange = new EventEmitter();
        this.clickedOnStep$ = new EventEmitter();
    }
    UtilService.prototype.getCountries = function (available) {
        return COUNTRIES.filter(function (c) {
            return available !== undefined ? c.available === available : true;
        });
    };
    UtilService.prototype.getTimeZones = function () {
        var timezones = [];
        TIMEZONES.map(function (t) {
            return t.utc.map(function (u) {
                if (u.indexOf('America') !== -1) {
                    timezones.push({
                        key: t.abbr,
                        value: u
                    });
                }
            });
        });
        return timezones;
    };
    // Use with (keydown) event
    UtilService.prototype.restrictAplhabets = function (event, numberRestriction) {
        var x = event.which || event.keyCode;
        if (event.ctrlKey || event.shiftKey || x === 9) {
            return true;
        }
        if ((x >= 48 && x <= 57) ||
            x === 8 ||
            (x >= 35 && x <= 40) ||
            x === 46 ||
            (x >= 96 && x <= 105) ||
            x === 86) {
            return this.restrictInputLength(event, numberRestriction);
        }
        else {
            return false;
        }
    };
    // Use with (keydown) event
    UtilService.prototype.restrictInputLength = function (event, restrictionNumber) {
        var x = event.which || event.keyCode;
        var value = event.target.value;
        var hasSelection = event.target.selectionStart !== value.length;
        var hasNavigationKeys = (x >= 35 && x <= 40) || event.ctrlKey || event.shiftKey;
        if (restrictionNumber &&
            value.length + 1 > restrictionNumber &&
            x !== 8 &&
            !hasSelection &&
            !hasNavigationKeys) {
            return false;
        }
        return true;
    };
    UtilService.prototype.dollarToCents = function (dollar) {
        return parseInt((parseFloat(dollar.toString()) * 100).toString(), 10);
    };
    UtilService.prototype.centsToDollar = function (cents) {
        return parseFloat(cents.toString()) / 100;
    };
    UtilService.prototype.removeWhiteSpace = function (obj) {
        for (var propName in obj) {
            if (obj[propName] === null ||
                obj[propName] === undefined ||
                $.trim(obj[propName]) === '' ||
                (Array.isArray(obj[propName]) && !obj[propName].length) ||
                (typeof obj[propName] === 'object' && this.isEmpty(obj[propName]))) {
                delete obj[propName];
            }
            else if (typeof obj[propName] != 'object') {
                obj[propName] = $.trim(obj[propName]);
            }
        }
        return obj;
    };
    UtilService.prototype.removeEmpty = function (obj) {
        for (var propName in obj) {
            if (obj[propName] === null ||
                obj[propName] === undefined ||
                obj[propName] === '' ||
                (Array.isArray(obj[propName]) && !obj[propName].length) ||
                (typeof obj[propName] === 'object' && this.isEmpty(obj[propName]))) {
                delete obj[propName];
            }
        }
        return obj;
    };
    UtilService.prototype.isEmpty = function (obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    };
    UtilService.prototype.copyToClipboard = function (value) {
        var selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = value;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    };
    UtilService.prototype.stringKeywordReplacer = function (msg, apiObject) {
        var _this = this;
        try {
            return msg.replace(/{([^}]+)}/g, function (match, m1) {
                if (m1.includes('$')) {
                    return Math.abs(_this.centsToDollar(_this.get(apiObject, m1.slice(1))));
                }
                if (m1.includes('#')) {
                    return _this.datePipe.transform(_this.get(apiObject, m1.slice(1)), DATE_TIME_FORMATE.APIKEY);
                }
                return _this.get(apiObject, m1);
            });
        }
        catch (e) {
            console.log(e);
        }
        return msg;
    };
    UtilService.prototype.objectResolver = function (path, obj) {
        return path.split('.').reduce(function (prev, curr) {
            return prev ? prev[curr] : null;
        }, obj || self);
    };
    UtilService.prototype.get = function (obj, key) {
        return key.split('.').reduce(function (o, x) {
            return o === undefined || o === null ? o : o[x];
        }, obj);
    };
    UtilService.prototype.generateWildcardObject = function (id) {
        try {
            var object = '';
            var prefix = id.split('_')[0];
            object = JSON.stringify({
                $or: [
                    {
                        'data.object._id': id
                    },
                    {
                    // ['data.object.' + INDENTIFIERS[prefix]]: id
                    }
                ]
            });
            return object;
        }
        catch (e) {
            console.log(e);
        }
    };
    UtilService.prototype.createFormData = function (object, form, namespace) {
        var formData = form || new FormData();
        for (var property in object) {
            if (!object.hasOwnProperty(property) ||
                object[property] === null ||
                object[property] === undefined) {
                continue;
            }
            var formKey = namespace ? namespace + "[" + property + "]" : property;
            if (object[property] instanceof Date) {
                formData.append(formKey, object[property].toISOString());
            }
            else if (typeof object[property] === 'object' &&
                !(object[property] instanceof File)) {
                this.createFormData(object[property], formData, formKey);
            }
            else {
                formData.append(formKey, object[property]);
            }
        }
        return formData;
    };
    UtilService.prototype.setValidators = function (form, control, validators) {
        form.setValidators(validators);
        form.get(control).updateValueAndValidity();
        return form;
    };
    UtilService.prototype.clearValidators = function (form, control) {
        form.clearValidators();
        form.get(control).updateValueAndValidity();
        return form;
    };
    Object.defineProperty(UtilService.prototype, "missedErrors", {
        // For missed API Errors
        get: function () {
            return this._missedError;
        },
        enumerable: true,
        configurable: true
    });
    UtilService.prototype.setMissedError = function (errMsg) {
        this._missedError = errMsg;
        this.missedErrors$.emit(this._missedError);
        console.log(this.missedErrors, 'this.missedErrors EMIT');
    };
    UtilService.prototype.clearMissedError = function () {
        this._missedError = '';
        this.missedErrors$.emit(this._missedError);
    };
    UtilService.prototype.showPaginationInfo = function (data, perPage, currentPage) {
        var lastPage = data.length / perPage;
        if (!this.isInt(lastPage)) {
            lastPage = Math.floor(lastPage) + 1;
        }
        if (data && data.length > 0) {
            var from = perPage * currentPage - perPage + 1;
            var to = perPage * currentPage;
            if (data.length % perPage === 0) {
                return "Showing " + from + " to " + to + " of " + data.length + " entries";
            }
            else {
                if (currentPage === lastPage) {
                    return "Showing " + from + " to " + ((data.length % perPage) + from - 1) + " of " + data.length + " entries";
                }
                else {
                    return "Showing " + from + " to " + to + " of " + data.length + " entries";
                }
            }
        }
        else {
            return '';
        }
    };
    UtilService.prototype.showPaginationInfoNew = function (total, perPage, currentPage) {
        var lastPage = total / perPage;
        if (!this.isInt(lastPage)) {
            lastPage = Math.floor(lastPage) + 1;
        }
        if (total > 0) {
            var from = perPage * currentPage - perPage + 1;
            var to = perPage * currentPage;
            if (total % perPage === 0) {
                return "Showing " + from + " to " + to + " of " + total + " entries";
            }
            else {
                if (currentPage === lastPage) {
                    return "Showing " + from + " to " + ((total % perPage) + from - 1) + " of " + total + " entries";
                }
                else {
                    return "Showing " + from + " to " + to + " of " + total + " entries";
                }
            }
        }
        else {
            return '';
        }
    };
    UtilService.prototype.isInt = function (n) {
        return n % 1 === 0;
    };
    UtilService.prototype.getCustomDatepickerYears = function (type, limit) {
        var current = new Date();
        return {
            year: type === 'MIN'
                ? current.getFullYear() - limit
                : type === 'MAX'
                    ? current.getFullYear() + limit
                    : current.getFullYear(),
            month: current.getMonth(),
            day: current.getDate()
        };
    };
    Object.defineProperty(UtilService.prototype, "getCurrentYear", {
        get: function () {
            var currentDate = new Date();
            return currentDate.getFullYear();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UtilService.prototype, "getCurrentMonth", {
        get: function () {
            var currentDate = new Date();
            return currentDate.getMonth() + 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UtilService.prototype, "getCurrentDay", {
        get: function () {
            var currentDate = new Date();
            return currentDate.getDate();
        },
        enumerable: true,
        configurable: true
    });
    UtilService.prototype.getHiddenStep = function () {
        var elementArr = [];
        // if (
        //   document.getElementsByClassName('default navigable') &&
        //   document.getElementsByClassName('default navigable').length
        // ) {
        //   const elem = document.getElementsByClassName('default navigable');
        //   if (elem && elem.length) {
        //     for (let i = 0; i < elem.length; i++) {
        //       $(elem[i]).css('cursor', 'pointer');
        //       elementArr.push(elem[i]);
        //     }
        //   }
        // }
        // if (
        //   document.getElementsByClassName('default') &&
        //   document.getElementsByClassName('default').length
        // ) {
        //   const elem_default = document.getElementsByClassName('default');
        //   if (elem_default && elem_default.length) {
        //     for (let i = 0; i < elem_default.length; i++) {
        //       $(elem_default[i]).css('cursor', 'pointer');
        //       elementArr.push(elem_default[i]);
        //     }
        //   }
        // }
        // if (
        //   document.getElementsByClassName('done navigable') &&
        //   document.getElementsByClassName('done navigable').length
        // ) {
        //   const elem_done = document.getElementsByClassName('done navigable');
        //   if (elem_done && elem_done.length) {
        //     for (let i = 0; i < elem_done.length; i++) {
        //       $(elem_done[i]).css('cursor', 'pointer');
        //       elementArr.push(elem_done[i]);
        //     }
        //   }
        // }
        // if (
        //   document.getElementsByClassName('current') &&
        //   document.getElementsByClassName('current').length
        // ) {
        //   const elem_cur = document.getElementsByClassName('current');
        //   if (elem_cur && elem_cur.length) {
        //     for (let i = 0; i < elem_cur.length; i++) {
        //       elementArr.push(elem_cur[i]);
        //     }
        //   }
        // }
        // if (
        //   document.getElementsByClassName('done') &&
        //   document.getElementsByClassName('done').length
        // ) {
        //   const elem_done = document.getElementsByClassName('done');
        //   if (elem_done && elem_done.length) {
        //     for (let i = 0; i < elem_done.length; i++) {
        //       $(elem_done[i]).css('cursor', 'pointer');
        //       elementArr.push(elem_done[i]);
        //     }
        //   }
        // }
        if ($('.steps-indicator  li.done') &&
            $('.steps-indicator  li.done').length) {
            var elem_done = $('.steps-indicator  li.done');
            if (elem_done && elem_done.length) {
                for (var i = 0; i < elem_done.length; i++) {
                    $(elem_done[i]).css('cursor', 'pointer');
                    elementArr.push(elem_done[i]);
                }
            }
        }
        this.hiddenStep(elementArr);
    };
    UtilService.prototype.hiddenStep = function (elementArr) {
        var _this = this;
        if (elementArr && elementArr.length) {
            for (var i = 0; i < elementArr.length; i++) {
                elementArr[i].addEventListener('click', function (e) {
                    _this.clickOnStep(e.target.textContent);
                });
            }
        }
        else {
            setTimeout(function () {
                _this.getHiddenStep();
            }, 200);
        }
    };
    UtilService.prototype.clickOnStep = function (stepTitle) {
        var _this = this;
        if (this.stepClick) {
            this.clickedOnStep$.emit(stepTitle);
            this.stepClick = false;
            setTimeout(function () {
                _this.stepClick = true;
            }, 1000);
        }
    };
    /**
     * Marks all controls in a form group as touched
     * @param formGroup - The form group to touch
     */
    UtilService.prototype.markFormGroupTouched = function (formGroup) {
        var _this = this;
        Object.values(formGroup.controls).forEach(function (control) {
            control.markAsTouched();
            if (control.controls) {
                _this.markFormGroupTouched(control);
            }
        });
    };
    UtilService.prototype.checkAllClasss = function (step) {
        var elem = $('.steps-indicator  li');
        if (elem && elem.length) {
            for (var i = 0; i < elem.length; i++) {
                $(elem[i]).addClass('default');
                if (elem[i] && i == step) {
                    $(elem[i]).addClass('current');
                }
                // console.log("ED ",i,elem[i].className);
            }
        }
    };
    UtilService.prototype.showStepAsDone = function (count) {
        var elementArr = [];
        if ($('.steps-indicator  li') &&
            $('.steps-indicator  li').length) {
            var elem = $('.steps-indicator  li');
            if (elem && elem.length) {
                for (var i = 0; i < elem.length; i++) {
                    if (i < count) {
                        $(elem[i]).addClass('done');
                    }
                    elementArr.push(elem[i]);
                }
            }
        }
        // if (
        //   document.getElementsByClassName('default navigable') &&
        //   document.getElementsByClassName('default navigable').length
        // ) {
        //   console.log(count);
        //   const elem = document.getElementsByClassName('default navigable');
        //   if (elem && elem.length) {
        //     for (let i = 0; i < elem.length; i++) {
        //       if (i < count) {
        //         $(elem[i]).addClass('done');
        //       }
        //       elementArr.push(elem[i]);
        //     }
        //   }
        // }
        // if (
        //   document.getElementsByClassName('default') &&
        //   document.getElementsByClassName('default').length
        // ) {
        // const elem_default = document.getElementsByClassName('default');
        // if (elem_default && elem_default.length) {
        // for (let i = 0; i < elem_default.length; i++) {
        //   $(elem_default[i]).addClass('done');
        //   elementArr.push(elem_default[i]);
        // }
        // }
        // }
        this.notFoundStepAsDone(elementArr, count);
    };
    UtilService.prototype.showStepDone = function (count) {
        var elementArr = [];
        if (document.getElementsByClassName('default') &&
            document.getElementsByClassName('default').length) {
            var elem = document.getElementsByClassName('default');
            if (elem && elem.length) {
                for (var i = 0; i < elem.length; i++) {
                    if (i < count) {
                        $(elem[i]).addClass('done');
                    }
                    elementArr.push(elem[i]);
                }
            }
        }
        this.notFoundStepAsDone(elementArr, count);
    };
    UtilService.prototype.notFoundStepAsDone = function (elementArr, count) {
        var _this = this;
        if (elementArr && elementArr.length === 0) {
            setTimeout(function () {
                _this.showStepAsDone(count);
            }, 200);
        }
    };
    UtilService.prototype.getStepIndexFromStepTitle = function (stepTitle) {
        var stepNumber = 0;
        // console.log(stepTitle);
        switch (stepTitle) {
            case 'Producer Information':
                stepNumber = 0;
                break;
            // case 'Additional Contact Information':
            //   stepNumber = 1;
            //   break;
            // case 'Private Accounting Contact':
            //   stepNumber = 1;
            //   break;
            case 'Technology Information':
                stepNumber = 1;
                break;
            case 'Billing Information':
                stepNumber = 2;
                break;
            case 'Products':
                stepNumber = 3;
                break;
            case 'Incentive (Pack) Information':
                stepNumber = 4;
                break;
            // case 'Technology Information':
            //   stepNumber = 4;
            //   break;
            case 'Commissions':
                stepNumber = 5;
                break;
            case 'Documents':
                stepNumber = 6;
                break;
            case 'Agreements':
                stepNumber = 7;
                break;
        }
        return stepNumber;
    };
    UtilService.prototype.showCurrentEditableStep = function (step) {
        // if (
        //   $('.steps-indicator  li') &&
        //   $('.steps-indicator  li').length
        // ) {
        //   const elem = $('.steps-indicator  li');
        //   if (elem && elem.length) {
        //     for (let i = 0; i < elem.length; i++) {
        //       if (i = step) {
        //         $(elem[i]).addClass('current');
        //       }
        //     }
        //   }
        // }
    };
    UtilService.prototype.getDateFormate = function (data) {
        var date = new Date(data);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var dt = date.getDate();
        var dat = (month < 10 ? '0' : '') +
            month +
            '/' +
            (dt < 10 ? '0' : '') +
            dt +
            '/' +
            year;
        return dat;
    };
    UtilService.prototype.removeDate = function (formGroup, formControl) {
        formGroup.get(formControl).setValue(null);
        formGroup.get(formControl).markAsDirty();
    };
    UtilService.prototype.getPageList = function (toalPage, currentPage) {
        var from = Math.floor(currentPage / 5) * 5;
        var to = (Math.floor(currentPage / 5) + 1) * 5;
        if (from == currentPage && from != 1) {
            from = from - 1;
            to = to - 1;
        }
        if (to > toalPage) {
            to = toalPage;
            from = to - 5;
        }
        from = (from < 1) ? 1 : from;
        // create an array of pages to ng-repeat in the pager control
        var pages = [];
        if (currentPage > 4 && from > 2) {
            pages.push({ page: 1, label: '1 .. ' });
        }
        else if (from == 2) {
            from = 1;
        }
        for (var i = from; i <= to; i++) {
            pages.push({ page: i, label: i });
        }
        if (toalPage > to) {
            pages.push({ page: toalPage, label: ((to + 1) == toalPage) ? toalPage : ' .. ' + toalPage });
        }
        return pages;
    };
    UtilService.prototype.checkProductVersion = function (dealVersion) {
        if (parseFloat(dealVersion) == parseFloat('3.0'))
            return true;
        else
            return false;
    };
    UtilService.prototype.toggleDivClass = function (target, cls) {
        if ($("#" + target).hasClass(cls)) {
            $("#" + target).removeClass(cls);
            $(".hidden-xl-up .fa-minus").removeClass("d-none");
            $(".hidden-xl-up .fa-plus").addClass("d-none");
        }
        else {
            $("#" + target).addClass(cls);
            $(".hidden-xl-up .fa-minus").addClass("d-none");
            $(".hidden-xl-up .fa-plus").removeClass("d-none");
        }
    };
    return UtilService;
}());
export { UtilService };
