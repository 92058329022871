<form [formGroup]="PPMCreateFrom" (ngSubmit)="createRepairForm()" class="form-horizontal form-material"
  id="PPMCreateFrom" novalidate>
  <div class="modal-header" [ngStyle]="{'background-color': getCatAttr(product,'primary')}" style="color: #fff!important;">
        <h4 class="modal-title"  style="color: #fff!important;">{{getCatAttr(product,'name')}} Pre-Paid Maintenance Worksheet</h4>
        <span class="btn btn-sm float-right" (click)="activeModal.close()">
          <i class="mdi mdi-close"></i>
        </span>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <div class="col-12">
        <h5 [ngStyle]="{
          'color': getCatAttr(product,'primary')
        }">PRODUCER INFORMATION</h5>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-xl-6"><b>Agent / Representative: </b>{{agentName}}</div>
      <div class="col-12 col-xl-6"><b>Date: </b>{{dealData.createdAt | date: 'MM/dd/yyyy'}}</div>
      <div class="col-12 col-xl-6"><b>Producer Name: </b>{{dealData.name}}</div>
      <div class="col-12 col-xl-6"><b>Producer Group: </b></div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <h5 [ngStyle]="{
          'color': getCatAttr(product,'primary')
        }">PLAN INFORMATION</h5>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-4">
        <label [ngStyle]="{
          'color': getCatAttr(product,'primary')
        }">TYPE</label>
        <div class="custom-control custom-checkbox p-t-0">
          <input class="custom-control-input" type="checkbox" id="scheduledCare" name="scheduledCare"
            formControlName="scheduledCare" [checked]="PPMCreateFrom.get('scheduledCare').value"
            (click)="PPMCreateFrom.get('scheduledCare').setValue($event.target.checked);">
          <label for="scheduledCare" class="custom-control-label">Scheduled Care</label>
        </div>
        <div class="custom-control custom-checkbox p-t-0">
          <input class="custom-control-input" type="checkbox" id="plusCare" name="plusCare" formControlName="plusCare"
            [checked]="PPMCreateFrom.get('plusCare').value"
            (click)="PPMCreateFrom.get('plusCare').setValue($event.target.checked);">
          <label for="plusCare" class="custom-control-label">Plus Care</label>
        </div>
      </div>
      <div class="col-4">
        <label [ngStyle]="{
          'color': getCatAttr(product,'primary')
        }">VEHICLE TYPES</label>
        <div class="custom-control custom-checkbox p-t-0">
          <input class="custom-control-input" type="checkbox" id="vechileNew" name="vechileNew"
            formControlName="vechileNew" [checked]="PPMCreateFrom.get('vechileNew').value"
            (click)="PPMCreateFrom.get('vechileNew').setValue($event.target.checked);">
          <label for="vechileNew" class="custom-control-label">New</label>
        </div>
        <div class="custom-control custom-checkbox p-t-0">
          <input class="custom-control-input" type="checkbox" id="vechileCertifiedPreOwned"
            name="vechileCertifiedPreOwned" formControlName="vechileCertifiedPreOwned"
            [checked]="PPMCreateFrom.get('vechileCertifiedPreOwned').value"
            (click)="PPMCreateFrom.get('vechileCertifiedPreOwned').setValue($event.target.checked);">
          <label for="vechileCertifiedPreOwned" class="custom-control-label">Certified Pre-Owned</label>
        </div>
        <div class="custom-control custom-checkbox p-t-0">
          <input class="custom-control-input" type="checkbox" id="vechilePreOwned" name="vechilePreOwned"
            formControlName="vechilePreOwned" [checked]="PPMCreateFrom.get('vechilePreOwned').value"
            (click)="PPMCreateFrom.get('vechilePreOwned').setValue($event.target.checked);">
          <label for="vechilePreOwned" class="custom-control-label">Pre-Owned</label>
        </div>
      </div>
      <div class="col-4">
        <label [ngStyle]="{
          'color': getCatAttr(product,'primary')
        }">PRIVATE LABEL</label>
        <div class="custom-control custom-checkbox p-t-0">
          <input class="custom-control-input" type="checkbox" id="privateYes" name="privateYes"
            formControlName="privateYes" [checked]="PPMCreateFrom.get('privateYes').value"
            (click)="PPMCreateFrom.get('privateYes').setValue($event.target.checked);">
          <label for="privateYes" class="custom-control-label">Yes</label>
        </div>
        <div class="custom-control custom-checkbox p-t-0">
          <input class="custom-control-input" type="checkbox" id="privateNo" name="privateNo"
            formControlName="privateNo" [checked]="PPMCreateFrom.get('privateNo').value"
            (click)="PPMCreateFrom.get('privateNo').setValue($event.target.checked);">
          <label for="privateNo" class="custom-control-label">No</label>
        </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-2">Vehicle Age Limit<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper [apiErrorType]="'vechileAgeLimit'" [control]="PPMCreateFrom.controls['vechileAgeLimit']"
          [controlName]="'vechileAgeLimit'">
          <input class="form-control" type="text" formControlName="vechileAgeLimit" />
        </app-form-error-wrapper>
      </div>
      <label class="col-12 col-xl-2">model years</label>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-2">Miles On Odometer<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper [apiErrorType]="'milesOnOdometer'" [control]="PPMCreateFrom.controls['milesOnOdometer']"
          [controlName]="'milesOnOdometer'">
          <input class="form-control" type="text" formControlName="milesOnOdometer" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <h5 [ngStyle]="{
          'color': getCatAttr(product,'primary')
        }">TERMS (Months/Miles)</h5>
      </div>
    </div>
    <div class="form-group row">
      <ng-container *ngFor="let month of months; let i = index">
        <div class="col-12 col-xl-3">
          <div class="row d-flex m-b-5">
            <div class="col-5">
              <app-form-error-wrapper [apiErrorType]="'month' + (i + 1)"
                [control]="PPMCreateFrom.controls['month' + (i + 1)]" [controlName]="'month' + (i + 1)">
                <input class="form-control" type="text" [formControlName]="'month' + (i + 1)" />
              </app-form-error-wrapper>
            </div>
            <label class="col-1 p-0 mt-2">&nbsp;/</label>
            <div class="col-6 p-0">
              <app-form-error-wrapper [apiErrorType]="'miles' + (i + 1)"
                [control]="PPMCreateFrom.controls['miles' + (i + 1)]" [controlName]="'miles' + (i + 1)">
                <input class="form-control" type="text" [formControlName]="'miles' + (i + 1)" />
              </app-form-error-wrapper>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-2">Eligible Vehicles<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-10">
        <app-form-error-wrapper [apiErrorType]="'eligibleVehicles'"
          [control]="PPMCreateFrom.controls['eligibleVehicles']" [controlName]="'eligibleVehicles'">
          <input class="form-control" type="text" formControlName="eligibleVehicles" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <h5 [ngStyle]="{
          'color': getCatAttr(product,'primary')
        }">SERVICES AND REIMBURSEMENTS</h5>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <div class="row text-bold">
          <div class="col-4">SERVICE</div>
          <div class="col-4">INTERVAL</div>
          <div class="col-4">REIMBURSEMENT</div>
        </div>
      </div>
      <div class="col-12">
        <ng-container *ngFor="let service of services; let i = index">
          <div class="row d-flex m-b-5">
            <div class="col-4">
              <app-form-error-wrapper [apiErrorType]="'service' + (i + 1)"
                [control]="PPMCreateFrom.controls['service' + (i + 1)]" [controlName]="'service' + (i + 1)">
                <input class="form-control" type="text" [formControlName]="'service' + (i + 1)" />
              </app-form-error-wrapper>
            </div>
            <div class="col-4">
              <app-form-error-wrapper [apiErrorType]="'interval' + (i + 1)"
                [control]="PPMCreateFrom.controls['interval' + (i + 1)]" [controlName]="'interval' + (i + 1)">
                <input class="form-control" type="text" [formControlName]="'interval' + (i + 1)" />
              </app-form-error-wrapper>
            </div>
            <div class="col-4">
              <app-form-error-wrapper [apiErrorType]="'reimbursement' + (i + 1)"
                [control]="PPMCreateFrom.controls['reimbursement' + (i + 1)]" [controlName]="'reimbursement' + (i + 1)">
                <input class="form-control" type="text" [formControlName]="'reimbursement' + (i + 1)" />
              </app-form-error-wrapper>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">
      Cancel
    </button>
    <button class="btn btn-primary" [disabled]="PPMCreateFrom.invalid || isLoading">
      <app-button-loader [isLoading]="isLoading" [label]="'Create Pre-Paid Maintenance Worksheet PDF'" (click)="checkValidfields()"></app-button-loader>
    </button>
  </div>
</form>