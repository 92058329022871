<form
  [formGroup]="repairCreateFrom"
  (ngSubmit)="createRepairForm()"
  class="form-horizontal form-material"
  id="repairCreateFrom"
  novalidate
>
  <div class="modal-header">
    <h4 class="modal-title">Repair Facility Form</h4>
    <span class="btn btn-sm float-right" (click)="activeModal.close()">
      <i class="mdi mdi-close"></i>
    </span>
  </div>
  <div class="modal-body">

    <div class="form-group row">
      <div class="col-12">
        <h5>DEALER INFORMATION</h5>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12 col-xl-6"><b>Agent / Representative: </b>{{agentName}}</div>
      <div class="col-12 col-xl-6"><b>Date: </b>{{dealData.createdAt | date: 'MM/dd/yyyy'}}</div>
      <div class="col-12 col-xl-6"><b>Dealership Name/DBA: </b>{{dealData.dba}}</div>
      <div class="col-12 col-xl-6"><b>Legal Name: </b>{{dealData.name}}</div>
      <div class="col-12 col-xl-6"><b>Dealership Group: </b></div>
      <div class="col-12 col-xl-6"><b>Tax ID: </b>{{dealData.companyFEIN}}</div>
      <div class="col-12 col-xl-6"><b>Street Address: </b>{{dealData.name}}</div>
      <div class="col-12 col-xl-6"><b>City/State/Zip: </b>{{dealData.city}} {{dealData.state}} {{dealData.zipCode}}</div>
      <div class="col-12 col-xl-6"><b>Phone Number: </b>{{dealData.phoneNumber}}</div>
      <div class="col-12 col-xl-6"><b>Website: </b>{{dealData.website}}</div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <h5>PRIMARY AUTHORIZATION CONTRACT</h5>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <div class="form-group d-xl-flex align-items-center">
          <div class="custom-control custom-checkbox p-t-0">
            <input class="custom-control-input" type="checkbox"
            id="sameAsPrimary"
            name="sameAsPrimary"
            (click)="sameAsPrimary($event.target.checked);"
            >
            <label for="sameAsPrimary" class="custom-control-label">Same As Primary</label>
          </div>
        </div>
      </div>
      <label class="col-12 col-xl-2">Name<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'primaryName'"
          [control]="repairCreateFrom.controls['primaryName']"
          [controlName]="'Primary Name'"
        >
          <input class="form-control" type="text" formControlName="primaryName" />
        </app-form-error-wrapper>
      </div>
      <label class="col-12 col-xl-2">Title<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'primaryTitle'"
          [control]="repairCreateFrom.controls['primaryTitle']"
          [controlName]="'Primary Title'"
        >
          <input class="form-control" type="text" formControlName="primaryTitle" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-2">Phone<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'primaryPhone'"
          [control]="repairCreateFrom.controls['primaryPhone']"
          [controlName]="'Primary Phone'"
        >
          <input
            class="form-control"
            type="tel"
            mask="000-000-0000"
            formControlName="primaryPhone"
          />
        </app-form-error-wrapper>
      </div>
      <label class="col-12 col-xl-2">Email<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'primaryEmail'"
          [control]="repairCreateFrom.controls['primaryEmail']"
          [controlName]="'primaryEmail'"
        >
          <input class="form-control" type="primaryEmail" formControlName="primaryEmail" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <h5>ALTERNATE AUTHORIZATION CONTRACT</h5>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <div class="form-group d-xl-flex align-items-center">
          <div class="custom-control custom-checkbox p-t-0">
            <input class="custom-control-input" type="checkbox"
            id="sameAsPrimary1"
            name="sameAsPrimary1"
            (click)="sameAsPrimary1($event.target.checked);"
            >
            <label for="sameAsPrimary1" class="custom-control-label">Same As Primary</label>
          </div>
        </div>
      </div>
      <label class="col-12 col-xl-2">Name<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'alternateName'"
          [control]="repairCreateFrom.controls['alternateName']"
          [controlName]="'Primary Name'"
        >
          <input class="form-control" type="text" formControlName="alternateName" />
        </app-form-error-wrapper>
      </div>
      <label class="col-12 col-xl-2">Title<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'alternateTitle'"
          [control]="repairCreateFrom.controls['alternateTitle']"
          [controlName]="'alternate Title'"
        >
          <input class="form-control" type="text" formControlName="alternateTitle" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-2">Phone<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'alternatePhone'"
          [control]="repairCreateFrom.controls['alternatePhone']"
          [controlName]="'alternate Phone'"
        >
          <input
            class="form-control"
            type="tel"
            mask="000-000-0000"
            formControlName="alternatePhone"
          />
        </app-form-error-wrapper>
      </div>
      <label class="col-12 col-xl-2">Email<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'alternateEmail'"
          [control]="repairCreateFrom.controls['alternateEmail']"
          [controlName]="'alternateEmail'"
        >
          <input class="form-control" type="alternateEmail" formControlName="alternateEmail" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <div class="col-12">
        <h5>REPAIR FACILITY INFORMATION</h5>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-2">Name<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'repairFacilityName'"
          [control]="repairCreateFrom.controls['repairFacilityName']"
          [controlName]="'repairFacility Name'"
        >
          <input class="form-control" type="text" formControlName="repairFacilityName" />
        </app-form-error-wrapper>
      </div>
      <label class="col-12 col-xl-2">Type</label>
      <div class="col-12 col-xl-4 d-flex align-items-center">
          <div class="custom-control custom-checkbox p-t-0">
            <input class="custom-control-input" type="checkbox"
            id="repairFacilityTypeOnSite"
            name="repairFacilityTypeOnSite"
            [checked]="repairCreateFrom.get('repairFacilityTypeOnSite').value"
            (click)="repairCreateFrom.get('repairFacilityTypeOnSite').setValue($event.target.checked);">
            <label for="repairFacilityTypeOnSite" class="custom-control-label">OnSite</label>
          </div>
          <div class="custom-control custom-checkbox p-t-0 ml-1">
            <input class="custom-control-input" type="checkbox"
            id="repairFacilityTypePreferredTieBack"
            name="repairFacilityTypePreferredTieBack"
            [checked]="repairCreateFrom.get('repairFacilityTypePreferredTieBack').value"
            (click)="repairCreateFrom.get('repairFacilityTypePreferredTieBack').setValue($event.target.checked);">
            <label for="repairFacilityTypePreferredTieBack" class="custom-control-label">Preferred Tie Back</label>
          </div>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-2">Address<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'repairFacilityAddress'"
          [control]="repairCreateFrom.controls['repairFacilityAddress']"
          [controlName]="'repair Facility Address'"
        >
          <input class="form-control" type="text" formControlName="repairFacilityAddress" />
        </app-form-error-wrapper>
      </div>
      <label class="col-12 col-xl-2">Tax Id<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'repairFacilityTaxId'"
          [control]="repairCreateFrom.controls['repairFacilityTaxId']"
          [controlName]="'repairFacilityTaxId'"
        >
          <input
            class="form-control"
            type="text"
            formControlName="repairFacilityTaxId"
          />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-2">Phone Number<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'repairFacilityPhone'"
          [control]="repairCreateFrom.controls['repairFacilityPhone']"
          [controlName]="'repairFacility Phone'"
        >
          <input
            class="form-control"
            type="tel"
            mask="000-000-0000"
            formControlName="repairFacilityPhone"
          />
        </app-form-error-wrapper>
      </div>
      <label class="col-12 col-xl-2">Email<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'repairFacilityEmail'"
          [control]="repairCreateFrom.controls['repairFacilityEmail']"
          [controlName]="'repairFacility  Email'"
        >
          <input class="form-control" type="email" formControlName="repairFacilityEmail" />
        </app-form-error-wrapper>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-12 col-xl-2">Labor Rate<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'repairFacilityLaborRate'"
          [control]="repairCreateFrom.controls['repairFacilityLaborRate']"
          [controlName]="'repairFacilityLaborRate'"
        >
          <input
            class="form-control"
            type="text"
            currencyMask
            [options]="{ prefix: '$ ' }"
            formControlName="repairFacilityLaborRate"
          />
        </app-form-error-wrapper>
      </div>
      <label class="col-12 col-xl-2">Labor Tax<sup class="required-label">*</sup></label>
      <div class="col-12 col-xl-4">
        <app-form-error-wrapper
          [apiErrorType]="'repairFacilityLaborTax'"
          [control]="repairCreateFrom.controls['repairFacilityLaborTax']"
          [controlName]="'repairFacilityLaborTax'"
        >
          <input
            class="form-control"
            type="text"
            formControlName="repairFacilityLaborTax"
          />
        </app-form-error-wrapper>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="activeModal.dismiss()"
    >
      Cancel
    </button>
    <button
      class="btn btn-primary"
      [disabled]="repairCreateFrom.invalid || isLoading"
    >
      <app-button-loader
        [isLoading]="isLoading"
        [label]="'Create Repair Facility PDF'"
        (click)="checkValidfields()"
      ></app-button-loader>
    </button>
  </div>
</form>
